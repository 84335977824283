'use client'

import { AuthProvider } from './components/providers/AuthProvider'
import { Toaster } from 'react-hot-toast'

export default function ClientWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <AuthProvider>
      {children}
      <Toaster position="bottom-center" />
    </AuthProvider>
  )
} 